import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Sling as Hamburger } from 'hamburger-react'
import { Row, Container, Col, Modal, Button } from 'react-bootstrap';
import facebookLogo from './images/facebook.png';
import instagramLogo from './images/instagram.png';
import spotifyLogo from './images/spotify.png';
import appleLogo from './images/apple.svg';
import Music from './pages/Music';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

let track = false;

const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('228581022555042', advancedMatching, options);
ReactPixel.revokeConsent();

function App() {
  const [isOpen, setOpen] = useState(false);
  const [show, setShow] = useState(true);

  const handleAccept = () => {
    track = true;
    ReactPixel.grantConsent();
    setShow(false);
  };

  const handleDecline = () => {
    track = false;
    ReactPixel.revokeConsent();
    setShow(false);
  }

  const handleShow = () => setShow(true);

  document.title = "Stereo Club";

  if(track) {
    ReactPixel.pageView(); // For tracking page view
  }
  

  return (
    <div className="App">
        <Router>

        <Container fluid id="top-bar">
            <Row>
                <Col>
                    <div className="menu-container">
                            <Hamburger toggled={isOpen} toggle={setOpen}/>
                            {
                                isOpen ? <div className="menu-items">
                                    <Link to="/">HOME</Link>
                                    <Link to="/music">MUSIC</Link>
                                    {/* <Link to="/gallery">GALLERY</Link> */}
                                </div> : <div/>
                            }                            
                    </div>
                    
                </Col>
                <Col id="main-title">
                    STEREO CLUB
                </Col>
                <Col>
                    <div id="logos-div">
                        <a href="https://open.spotify.com/artist/0erSKR1JB2jDIYWk1jBeIT" target="_blank" rel="noopener noreferrer">
                            <img className="logos" src={spotifyLogo} alt="The spotify logo. Pressing this will take you to our spotify page."/>
                        </a>
                        
                        <a href="https://www.instagram.com/stereoclubofficial/?hl=en" target="_blank" rel="noopener noreferrer">
                            <img className="logos" src={instagramLogo} alt="The instagram logo. Pressing this will take you to our instagram page."/>
                        </a>

                        <a href="https://www.facebook.com/StereoClubOfficial" target="_blank" rel="noopener noreferrer">
                        <img className="logos" src={facebookLogo} alt="The facebook logo. Pressing this will take you to our facebook page."/>
                        </a>

                        <a href="https://music.apple.com/us/artist/stereo-club/1569505263" target="_blank" rel="noopener noreferrer">
                        <img className="logos" src={appleLogo} alt="The apple music logo. Pressing this will take you to our apple music page."/>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>

        <Modal show={show} onHide={handleDecline} centered>
            <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    We use online advertising to keep you aware of what we're up to and to help you listen to and
                    find our music.<br/> <br/>
                    You may see Stereo Club ads when you are on other websites or apps, such as 
                    Social Media. We manage this through ad exchanges and online advertising technologies.<br/><br/>
                    <strong>Information Sharing</strong><br/><br/>
                    Stereo Club does not collect, store or sell any of your personal information.<br/><br/>
                    However, we do share data with the following categories of companies as an essential part of advertising our music:<br/><br/>
                    <ul>
                        <li>Professional service providers, such as marketing agencies, advertising partners and website hosts, who help us run our band.
                        For example, we use Facebook Custom Audiences to deliver targeted adverts to individuals who visit our website.</li><br/>
                        <li>Affiliates who help us reach out to potential new listeners or promote us on their websites.</li>
                    </ul>
                    <strong>Cookies Explained</strong>
                    <br/><br/>
                    Cookies are text files with small pieces of data that are used to identify your computer as you use a computer network. Specific cookies known as HTTP cookies are used to identify specific users and improve your web browsing experience.
                    <br/><br/>
                    Data stored in a cookie is created by the server upon your connection. This data is labeled with an ID unique to you and your computer.
                    <br/><br/>
                    When the cookie is exchanged between your computer and the network server, the server reads the ID and knows what information to specifically serve to you.
                    <br/><br/>
                    Cookies installed from our website remember that you visited this site and may share data with third party advertisers.
                    <br/><br/>
                    You can decline our Cookies below. Please keep in mind that removing or blocking cookies can negatively impact your future user experience.
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleDecline}>
                    Decline Cookies
                </Button>
                <Button variant="primary" onClick={handleAccept}>
                    Accept Cookies
                </Button>
                </Modal.Footer>
        </Modal>

        <Switch>
            <Route path="/music">
                <Music/>
            </Route>
            {/* <Route path="/gallery">
                <Gallery/>
            </Route> */}
            <Route path="/">
                <Home/>
            </Route>
        </Switch>

        </Router>

        <Container fluid id="footer">
                <p>
                    © Stereo Club Ltd
                </p>
                <p id="band-contact">
                    band@stereoclubofficial.com
                </p>
                <p id='privacy-notice' onClick={handleShow}>
                    privacy notice
                </p>
        </Container>
    </div>
  );
}

export default App;