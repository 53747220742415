import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Col } from 'react-bootstrap';
import parma from '../images/parma.png';
import wys from '../images/wys.png'

function Music() {
  return (
      <Container fluid id="music-container">
        <Row>
            <Col>  
                <h2 id="new-music-message">
                    NEW MUSIC ALWAYS ON THE WAY
                </h2>           
            </Col>
        </Row>
        {/* <Row>
            <Col>
                <a href="https://open.spotify.com/track/3DKJzV7yuBXdU7uwomSKtV?si=d982dbddd391466c" target="_blank" rel="noopener noreferrer">
                    <img className="single-image" src={parma} alt="Single artwork for Parma Violet Gin. Click to access the song on Spotify."/>
                </a>
            </Col>

            <Col>
                <a href="https://open.spotify.com/album/4YV7qQr9tnAQE1jKlN3SPx?si=1oOoBicATxKz6beZJd9cqw&dl_branch=1" target="_blank" rel="noopener noreferrer">
                    <img className="single-image" src={wys} alt="Single artwork for Want You Still. Click to access the song on Spotify."/>
                </a>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2 className="music-subtitle">
                    Parma Violet Gin
                </h2> 
            </Col>

            <Col>
                <h2 className="music-subtitle">
                    Want You Still
                </h2>    
            </Col>
        </Row> */}
        <Row>
            <Col>
            <iframe src="https://open.spotify.com/embed/album/6TzXJwOEaOuPc7VgLkqboy?utm_source=generator" width="80%" height="100%" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </Col>
        </Row>
      </Container>
  );
}

export default Music;