import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Col } from 'react-bootstrap';



function Home() {
  
  return (
      <Container id="home-container" fluid>
            <a href="https://open.spotify.com/album/6TzXJwOEaOuPc7VgLkqboy?si=_Je7aIwGSCujgZZZkYXolg" target="_blank" rel="noopener noreferrer">
              LISTEN NOW TO THE NEW SINGLE<br/>STAY YOUNG
            </a>
      </Container>
  );
}

export default Home;