import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import React from "react";

import 'swiper/swiper.min.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';


function Gallery() {


  return (
      <Container id="gallery-container">
        
           COMING SOON...
            <br />
        <a href="https://www.instagram.com/stereoclubofficial/?hl=en" target="_blank" rel="noopener noreferrer">
           CHECK OUR INSTAGRAM FOR MORE PHOTOS
        </a>
        
      </Container>
  );
}

export default Gallery;